import gql from "graphql-tag"

export const FIND_NEWS_BY_TAGS = gql`
  query Tags($tagsNews: [String]) {
    tags(where: {slug: $tagsNews}) {
      nodes {
        databaseId
        name
        posts(first: 100) {
          edges {
            node {
              title
              date
              slug
              featuredImage {
                sourceUrl
              }
            }
          }
        }
      }
    }
  }  
`